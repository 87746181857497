angular.module('noram.module.users',
	[
		'ngMessages',
		'ngAnimate',
		'ngMaterial',
		'mp.autoFocus',
		'noram.service.ajax',
		'noram.service.alert',
		'noram.service.error'
	])
	.config(function($mdThemingProvider) {
		// Configure a white color pallete
		$mdThemingProvider.definePalette('white', {
		    '50': 'FAFAFA',
		    '100': 'F5F5F5',
		    '200': 'EEEEEE',
		    '300': 'E0E0E0',
		    '400': 'BDBDBD',
		    '500': 'FFFFFF',
		    '600': '757575',
		    '700': '616161',
		    '800': '424242',
		    '900': '212121',
		    'A100': 'F1F1F1',
		    'A200': 'EFEFEF',
		    'A400': 'BFBFBF',
		    'A700': '676767',
		    'contrastDefaultColor': 'dark',
		    'contrastDarkColors': ['50', '100', '200', '300', '400', '500'],
		    'contrastLightColors': ['600','700','800','900']
		});
	    // Configure a red theme to use for error dialog
	    $mdThemingProvider.theme('md-error', 'default').primaryPalette('red');
	    // Configure a white theme for toolbars
	    $mdThemingProvider.theme('md-white', 'default').primaryPalette('white');
	    // Configure a dark theme
	    $mdThemingProvider.theme('md-dark', 'default').dark();
  	})

	.config(function($mdIconProvider) {
	    $mdIconProvider
	        .defaultIconSet('/packages/noram-labels/images/icons/mdi.svg')
	})

	.run(function($rootScope, AlertService){
		$rootScope.loading = false;
    	if(session[0])
    	{
        	AlertService.add('error',session[0]);
    	}
    	else if(session[1])
    	{
        	AlertService.add('notice', session[1]);
    	}
    	else if(session[2])
    	{
        	AlertService.add('success', session[2]);
    	}
    });

(function() {
	angular.module('noram.module.users')
		.controller('AppController', ['$mdSidenav','$mdToast',AppController]);

	function AppController($mdSidenav,$mdToast) {
		var vm = this;
		vm.toggleNav = function(navID) {
			$mdSidenav(navID).toggle();
		}
	}
})();

(function() {
    angular.module('noram.module.users')
        .controller('ExternalUserController', ExternalUserController);

    function ExternalUserController($scope, $timeout, ajaxCall, AlertService)
    {
        var vm = this;

        vm.init = function() {
            vm.passwordLength = passwordLength;
            vm.error = false;
            vm.userName = '';
            vm.firstName = '';
            vm.lastName  = '';
            vm.password = '';
            vm.confirm = '';
            vm.email = '';
        };

        vm.init();

        if(typeof authtoken !== "undefined") { vm.token = authtoken; }
        if(typeof userId !== "undefined") { vm.userId = userId; }

        vm.signUp = function(form)
        {
            if (form.$valid)  {
                ajaxCall.makeRequest('/user_management/postExternalSignUp', {
                    email:      vm.email,
                    firstName:  vm.firstName,
                    lastName:   vm.lastName,
                    password:   vm.password
                }).then(function(response) {
                    $timeout(function() {
                        window.location.replace(response.data.redirect);
                    }, 2000);
                });
            } else {
                AlertService.add('warning', 'Please fill out all required fields');
            }
        };

        vm.updatePassword = function(form)
        {
            if (form.$valid)  {
                ajaxCall.makeRequest('/user_management/postResetPassword', {
                    userId:     vm.userId,
                    token:      vm.token,
                    password:   vm.password
                }).then(function(response) {
                    $timeout(function() {
                        window.location.replace(response.data.redirect);
                    }, 2000);
                });
            } else {
                AlertService.add('warning', 'Please fill out all required fields');
            }
        };
    }
})();

(function() {
    angular.module('noram.module.users')
        .controller('ForgotPasswordController', ForgotPasswordController);

    function ForgotPasswordController(AlertService, ajaxCall, $timeout) {

        var vm = this;

        vm.submit = function(form)
        {
            if (form.$valid) {
                var data = {
                    username: vm.username
                };

                ajaxCall.makeRequest('/user_management/requestReset',data)
                .then(function(response){
                    if (response.data.redirect) {
                        $timeout(function() {
                            window.location.replace(response.data.redirect);
                        }, 2000);
                    }
                });
            } else {
                AlertService.add('danger', 'Please fill out all fields correctly.');
            }
        };

    }
})();

(function() {
    angular.module('noram.module.users')
        .controller('InternalCreateUserController', InternalCreateUserController);

    function InternalCreateUserController($scope, $mdDialog, $timeout, ajaxCall, AlertService)
    {
        var vm = this;
        vm.error = false;
        vm.questions = questions;
        vm.passwordLength = passwordLength;

        // close the dialog box and reject the promise
        vm.cancel = function() {
            $mdDialog.cancel();
        };

        // closes the dialog box and resolve the promise
        // returns object: user
        vm.finish = function(user) {
            $mdDialog.hide(user);
        };

        vm.resetValues = function()
        {
            vm.firstName = '';
            vm.lastName  = '';
            vm.firstPassword = '';
            vm.secondPassword = '';
            vm.questionAnswers = [];
            $scope.createUserForm.$setPristine();
            $scope.createUserForm.$setUntouched();
        };

        vm.submit = function(form)
        {
            if (form.$valid) {
                ajaxCall.makeRequest('/user_management/postInternalSignUp', {
                    firstName:         vm.firstName,
                    lastName:          vm.lastName,
                    password:          vm.firstPassword,
                    confirmedPassword: vm.secondPassword,
                    questionAnswers:   vm.questionAnswers
                }).then(function(response){
                    if (response.data.redirect) {
                        $timeout(function() {
                            window.location.replace(response.data.redirect);
                        }, 2000);
                    }
                });
            } else {
                AlertService.add('danger', 'Please fill out all fields correctly.');
            }
        };
    }
})();

(function() {
    angular.module('noram.module.users')
        .controller('RolesPermissionController', RolesPermissionController);

    function RolesPermissionController($scope, $mdDialog, AlertService, ajaxCall) {
        var vm = this;

        // Bind variables passed on view initialization
        vm.roles = typeof roles === 'undefined' ? [] : roles;
        vm.permissions = typeof permissions === 'undefined' ? [] : permissions;

        // Gets the list of permissions with their associated roles
        vm.getPermissions = function() {
            ajaxCall.makeRequest('/user_management/roles/all_permissions',
                {}, 'get')
                .then(function (response) {
                    vm.permissions = response.data;
                });
        };

        // Gets the list of roles with their associated permissions
        vm.getRoles = function() {
            ajaxCall.makeRequest('/user_management/roles/list',
                {}, 'get')
                .then(function(response) {
                    vm.roles = response.data;
                });
        }

        // Default options for new roles
        vm.newRole = {
            isOpen: false,
            name: ''
        }

        // Opens the form for creating a new role
        vm.openNewRole = function() {
            vm.newRole.isOpen = true;
            vm.selectedRole = null;
        }

        // Closes the form for creating a new role
        vm.cancelNewRole = function() {
            vm.newRole.isOpen = false;
            vm.newRole.name = '';
        }

        // Checks if there are changes unsaved changes to the currently selected role before changing it
        vm.selectRole = function (role,$event) {
            if (vm.selectedRole) {
                if (vm.selectedRole.changes) {
                    var confirm = $mdDialog.confirm()
                        .title('Unsaved changes')
                        .content(vm.selectedRole.name + ' has unsaved changes.')
                        .ariaLabel('unsaved changes confirmation')
                        .targetEvent($event)
                        .ok('discard changes')
                        .cancel('cancel');
                    $mdDialog.show(confirm).then(function() {
                        // vm.saveRole();
                        vm.setSelectedRole(role);
                    }, function() {
                        // vm.setSelectedRole(role);
                    });
                } else {
                    vm.setSelectedRole(role);
                }
            } else {
                vm.setSelectedRole(role);
            }
        }

        // Sets the selected role
        vm.setSelectedRole = function(selectedRole) {
            // first reset the selected values
            angular.forEach(vm.permissions, function(permission) {
                permission.selected = false;
            });

            // loop through each permissions' roles and set the checked value for ones that match the selected role
            angular.forEach(vm.permissions, function(permission) {
                for (var i = 0, len = permission.roles.length; i < len; i++) {
                    role = permission.roles[i];
                    if (role.id === selectedRole.id) {
                        permission.selected = true;
                        break;
                    } else {
                        permission.selected = false;
                    }
                }
            });

            vm.selectedRole = {};
            angular.extend(vm.selectedRole, selectedRole);
        }

        // Checks if there are changes to the selected role before clearing it
        vm.closeSelectedRole = function ($event) {
            if (vm.selectedRole.changes) {
                var confirm = $mdDialog.confirm()
                    .title('Unsaved changes')
                    .content(vm.selectedRole.name + ' has unsaved changes.')
                    .ariaLabel('unsaved changes confirmation')
                    .targetEvent($event)
                    .ok('discard changes')
                    .cancel('cancel');
                $mdDialog.show(confirm).then(function() {
                    // vm.saveRole();
                    vm.clearSelectedRole();
                }, function() {
                    // vm.clearSelectedRole();
                });
            } else {
                vm.clearSelectedRole();
            }
        }

        // Unsets the selected role
        vm.clearSelectedRole = function() {
            vm.selectedRole = null;
            angular.forEach(vm.allPermissions, function(object, value, key) {
                object.selected = false;
            });
        }

        // Flags permission changes to selected role,
        // check this property before changing or clearing selected role
        vm.changePermission = function() {
            vm.selectedRole.changes = true;
        }

        // Saves new role
        vm.saveRole = function() {
            ajaxCall.makeRequest('/user_management/roles/add',{
                roleName: vm.newRole.name
                // permissions: vm.assignedPermissions
            }).then(function(response){
                // close the new role form
                vm.cancelNewRole();
                // add the new role to the list and set it as selected
                var savedRole = response.data.data;
                vm.roles.push(savedRole);
                vm.setSelectedRole(savedRole);
            });
        };

        // Updates roles with its new list of permissions
        vm.updateRolePermissions = function() {
            var permissions = [];
            angular.forEach(vm.permissions, function(permission) {
                if (permission.selected == true) {
                    permissions.push(permission);
                }
            });
            ajaxCall.makeRequest('/user_management/roles/update_permissions',{
                roleId:      vm.selectedRole.id,
                permissions: permissions
            }).then(function(response){
                // refresh the permissions object since it now has updated roles
                vm.permissions = response.data.data;
                vm.clearSelectedRole();
            });
        };

        // Displays a dialog to confirm deletion of the selected permission
        vm.confirmDeleteRole = function($event) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete this role?')
                .content(vm.selectedRole.name + ' will be completely removed from the system. Any users in this role will no longer have its permissions.')
                .ariaLabel('Confirm delete role')
                .targetEvent($event)
                .ok('delete')
                .cancel('cancel');
            $mdDialog.show(confirm).then(function() {
                vm.deleteRole();
            }, function() {
                // do nothing
            });
        }

        // Deletes selected role then refreshes the roles and permissions
        vm.deleteRole = function()
        {
            ajaxCall.makeRequest('/user_management/roles/delete',{
                roleId: vm.selectedRole.id
            }).then(function(){
                // remove the role from the list of roles
                var index = vm.roles.indexOf(vm.selectedRole);
                vm.roles.splice(index, 1);

                // unset the selected role
                vm.clearSelectedRole();

                // refresh the list of permissions
                vm.getPermissions();
            });
        };
    }
})();

(function() {
    angular.module('noram.module.users')
        .controller('UserPermissionController', UserPermissionController);

    function UserPermissionController($scope, $mdDialog, AlertService, ajaxCall) {
        var vm = this;

        // Bind variables passed on view initialization
        vm.allRoutes = typeof routes === 'undefined' ? [] : routes;
        vm.availablePermissionOptions = typeof permissions === 'undefined' ? [] : permissions;

        // Default options for new permissions
        vm.newPermission = {
            isOpen: false,
            name: ''
        }

        // Filters all routes to only those assigned to the selected Permission, or not assigned to any permission
        vm.routeFilter = function (route) {
            return route.permission === vm.selectedPermission.name || typeof route.permission == 'undefined';
        }

        // Opens the form for creating a new permission
        vm.openNewPermission = function() {
            vm.newPermission.isOpen = true;
            vm.selectedPermission = null;
        }

        // Closes the form for creating a new permission
        vm.cancelNewPermission = function() {
            vm.newPermission.isOpen = false;
            vm.newPermission.name = '';
        }

        // Checks whether a permission has any routes assigned to it
        vm.allowSubmit = function () {
            return !(vm.routesAssignedToCurrentPermission.length > 0);
        };

        // Checks if there are changes unsaved changes to the currently selected permission before changing it
        vm.selectPermission = function (permission,$event) {
            if (vm.selectedPermission) {
                if (vm.selectedPermission.changes) {
                    var confirm = $mdDialog.confirm()
                        .title('Unsaved changes')
                        .content(vm.selectedPermission.name + ' has unsaved changes.')
                        .ariaLabel('unsaved changes confirmation')
                        .targetEvent($event)
                        .ok('discard changes')
                        .cancel('cancel');
                    $mdDialog.show(confirm).then(function() {
                        // vm.savePermission();
                        vm.setSelectedPermission(permission);
                    }, function() {
                        // vm.setSelectedPermission(permission);
                    });
                } else {
                    vm.setSelectedPermission(permission);
                }
            } else {
                vm.setSelectedPermission(permission);
            }
        }

        // Sets the selected permission
        vm.setSelectedPermission = function(permission) {
            // loop through the routes and set the checked value for ones that match the selected permission's name
            angular.forEach(vm.allRoutes, function(object, value, key) {
                if ( object.permission == permission.name ) {
                    object.selected = true;
                } else {
                    object.selected = false;
                }
            });
            vm.selectedPermission = {};
            angular.extend(vm.selectedPermission, permission);
        }

        // Checks if there are changes to the selected permission before clearing it
        vm.closeSelectedPermission = function ($event) {
            if (vm.selectedPermission.changes) {
                var confirm = $mdDialog.confirm()
                    .title('Unsaved changes')
                    .content(vm.selectedPermission.name + ' has unsaved changes.')
                    .ariaLabel('unsaved changes confirmation')
                    .targetEvent($event)
                    .ok('discard changes')
                    .cancel('cancel');
                $mdDialog.show(confirm).then(function() {
                    // vm.savePermission();
                    vm.clearSelectedPermission();
                }, function() {
                    // vm.clearSelectedPermission();
                });
            } else {
                vm.clearSelectedPermission();
            }
        }

        // Unsets the selected permission
        vm.clearSelectedPermission = function() {
            vm.selectedPermission = null;
            angular.forEach(vm.allRoutes, function(object, value, key) {
                object.selected = false;
            });
        }

        // Gets the list of routes with their associated permissions
        vm.getAllRoutes = function () {
            ajaxCall.makeRequest('/user_management/permissions/permission_list')
                .then(function (response) {
                    vm.allRoutes = response.data.data;
                }).catch(function (response) {
                    postAjaxCall('error', response.data);
                });
        };

        // Gets the list of permissions
        vm.getPermissions = function () {
            ajaxCall.makeRequest('/user_management/permissions/list',
                {}, 'get')
                .then(function (response) {
                    vm.availablePermissionOptions = response.data;
                });
        };

        // Displays a dialog to confirm deletion of the selected permission
        vm.confirmDeletePermission = function($event) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete this permission?')
                .content(vm.selectedPermission.name + ' will be completely removed from the system. Its routes will be available to assign to other permissions.')
                .ariaLabel('Confirm delete permission')
                .targetEvent($event)
                .ok('delete')
                .cancel('cancel');
            $mdDialog.show(confirm).then(function() {
                vm.deletePermission();
            }, function() {
                // do nothing
            });
        }

        // Deletes the selected permission
        vm.deletePermission = function () {
            ajaxCall.makeRequest('/user_management/permissions/delete_permission', {
                'permissionId': vm.selectedPermission.id
            }).then(function () {
                vm.clearSelectedPermission();
                vm.getPermissions();
                vm.getAllRoutes();
            });
        };

        // Flags route changes to selected permission,
        // check this property before changing or clearing selected permission
        vm.changeRoute = function() {
            vm.selectedPermission.changes = true;
        }

        // Updates list of routes associated with selected permission
        vm.updatePermissionRoutes = function () {
            var routes = [];
            angular.forEach(vm.allRoutes, function(object, value, key) {
                if (object.selected == true) {
                    routes.push(object);
                }
            });
            ajaxCall.makeRequest('/user_management/permissions/update_permission_routes', {
                'permissionId': vm.selectedPermission.id,
                'permissionsRoutes': routes
            }).then(function(response) {
                vm.allRoutes = response.data.data;
                vm.clearSelectedPermission();
            });
        };

        // Saves new permission
        vm.savePermission = function () {
            ajaxCall.makeRequest('/user_management/permissions/add_permission', {
                'permissionName': vm.newPermission.name
                // 'permissionsRoutes': vm.routesAssignedToCurrentPermission
            }).then(function (response) {
                // postAjaxCall('success', 'Permission successfully added.');
                vm.cancelNewPermission();
                var savedPermission = response.data.data;
                vm.availablePermissionOptions.push(savedPermission);
                vm.setSelectedPermission(savedPermission);
            });
        };

        function postAjaxCall(successType, successMessage) {
            clearAssignedRoutes();
            vm.newPermissionName = '';
            vm.selectedPermission = '';
            AlertService.add(successType, successMessage);
        }
    }
})();

(function() {
    angular.module('noram.module.users')
        .controller('UserResetController', UserResetController);

    function UserResetController($timeout, AlertService, ajaxCall) {
        var vm          = this;

        vm.init = function() {
            vm.passwordLength  = passwordLength;
            vm.pageTitle       = 'Answer Security Question';
            vm.managerUsername = '';
            vm.managerPassword = '';
            vm.valid           = '';
            vm.managerApproval = '';
            vm.newPassword     = '';
            vm.user            = user;
            vm.question        = question;
        }

        vm.init();

        vm.confirmAnswerToSecurityQuestion = function()
        {
            ajaxCall.makeRequest('/user_management/confirmAnswerToSecurityQuestion',{
                userId:     vm.user.id,
                questionId: vm.question.id,
                answer:     vm.answer
            }).then(function(response){
                vm.valid = response.data.data.data.id;
                if (vm.valid) { vm.pageTitle = 'Manager Approval Required'; }
            });
        };

        vm.managerLogin = function()
        {
            ajaxCall.makeRequest('/user_management/managerConfirmation',{
                username: vm.managerUsername,
                password: vm.managerPassword
            }).then(function(response){
                vm.managerApproval = response.data.data;
                if (vm.managerApproval) { vm.pageTitle = 'Set New Password'; }
            });
        };

        vm.changePassword = function(form)
        {
            if (form.$valid) {
                ajaxCall.makeRequest('/user_management/changePassword',{
                    username: vm.user.username,
                    password: vm.password1
                }).then(function(response){
                    if (response.data.redirect) {
                        $timeout(function() {
                            window.location.replace(response.data.redirect);
                        }, 2000)
                    }

                });
            } else {
                AlertService.add('warning', 'Please fill out all fields correctly.');
            }
        };
    }
})();

(function() {
    angular.module('noram.module.users')
        .controller('UserRolesController', UserRolesController);

    function UserRolesController($scope, $mdDialog, AlertService, ajaxCall, $filter) {

        var vm = this;

        // Bind variables passed on view initialization
        vm.users         = typeof users === 'undefined' ? [] : users;
        vm.filteredUsers = vm.users;
        vm.roles         = typeof roles === 'undefined' ? [] : roles;
        vm.trashedUsers  = false;

        vm.searchUsersForm = {
            open: false,
            string: ''
        };

        vm.filterUsers = function() {
            if (vm.searchUsersForm.string == '') {
                vm.filteredUsers = vm.users;
                vm.filteredTrashedUsers = vm.trashedUsers;
            }
            vm.filteredUsers = $filter('filter')(vm.users, vm.searchUsersForm.string);
            if (vm.trashedUsers) {
                vm.filteredTrashedUsers = $filter('filter')(vm.trashedUsers, vm.searchUsersForm.string);
            }
        }

        vm.closeFilter = function() {
            vm.searchUsersForm.string = '';
            vm.searchUsersForm.open = false;
            vm.filteredUsers = vm.users;
        }

        vm.getTrashedUsers = function() {
            ajaxCall.makeRequest('/user_management/users/list/trashed', {}, 'get')
                .then(function(response) {
                    vm.trashedUsers = response.data;
                    vm.filteredTrashedUsers = vm.trashedUsers;
                });
        }

        // Gets the list of roles with their associated users
        vm.getRoles = function() {
            ajaxCall.makeRequest('/user_management/user_roles/all_roles',
                {}, 'get')
                .then(function (response) {
                    vm.Roles = response.data;
                });
        };

        // Checks if there are changes unsaved changes to the currently selected user before changing it
        vm.selectUser = function (user,$event) {
            if (vm.selectedUser) {
                if (vm.selectedUser.changes) {
                    var confirm = $mdDialog.confirm()
                        .title('Unsaved changes')
                        .content(vm.selectedUser.first_name + ' ' + vm.selectedUser.last_name + ' has unsaved changes.')
                        .ariaLabel('unsaved changes confirmation')
                        .targetEvent($event)
                        .ok('discard changes')
                        .cancel('cancel');
                    $mdDialog.show(confirm).then(function() {
                        vm.setSelectedUser(user);
                    }, function() {
                    });
                } else {
                    vm.setSelectedUser(user);
                }
            } else {
                vm.setSelectedUser(user);
            }
        };

        // Sets the selected role
        vm.setSelectedUser = function(selectedUser) {
            // first reset the selected values
            angular.forEach(vm.roles, function(role) {
                role.selected = false;
            });

            // loop through each roles' users and set the checked value for ones that match the selected user
            angular.forEach(vm.roles, function(role) {
                for (var i = 0, len = role.users.length; i < len; i++) {
                    user = role.users[i];
                    if (user.id === selectedUser.id) {
                        role.selected = true;
                        break;
                    } else {
                        role.selected = false;
                    }
                }
            });

            vm.selectedUser = {};
            angular.extend(vm.selectedUser, selectedUser);
        };

        // Checks if there are changes to the selected user before clearing it
        vm.closeSelectedUser = function ($event) {
            if (vm.selectedUser.changes) {
                var confirm = $mdDialog.confirm()
                    .title('Unsaved changes')
                    .content(vm.selectedUser.first_name + ' ' + vm.selectedUser.last_name + ' has unsaved changes.')
                    .ariaLabel('unsaved changes confirmation')
                    .targetEvent($event)
                    .ok('discard changes')
                    .cancel('cancel');
                $mdDialog.show(confirm).then(function() {
                    // vm.saveUser();
                    vm.clearSelectedUser();
                }, function() {
                    // vm.clearSelectedUser();
                });
            } else {
                vm.clearSelectedUser();
            }
        };

        // Unsets the selected role
        vm.clearSelectedUser = function() {
            vm.selectedUser = null;
            angular.forEach(vm.roles, function(role) {
                role.selected = false;
            });
        };

        // Flags role changes to selected user,
        // check this property before changing or clearing selected user
        vm.changeRoles = function() {
            vm.selectedUser.changes = true;
        };

        // Updates user with its new list of roles
        vm.updateUserRoles = function() {
            var roles = [];
            angular.forEach(vm.roles, function(role) {
                if (role.selected == true) {
                    roles.push(role);
                }
            });
            ajaxCall.makeRequest('/user_management/user_roles/update',{
                userId: vm.selectedUser.id,
                roles:  roles
            }).then(function(response){
                // refresh the roles object since it now has updated Users
                vm.roles = response.data.data;
                vm.clearSelectedUser();
            });
        };

        // Displays a dialog to confirm deletion of the selected user
        vm.confirmDeleteUser = function($event) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete this user?')
                .content(vm.selectedUser.first_name + ' ' + vm.selectedUser.last_name + ' will no longer be able to log in.')
                .ariaLabel('Confirm delete User')
                .targetEvent($event)
                .ok('delete')
                .cancel('Cancel');
            $mdDialog.show(confirm).then(function() {
                vm.deleteUser();
            }, function() {
                // do nothing
            });
        };

        // Deletes selected user then refreshes the users and roles
        vm.deleteUser = function() {
            ajaxCall.makeRequest('/user_management/users/delete',{
                userId: vm.selectedUser.id
            }).then(function(response){
                vm.users = response.data.data.allUsers;
                vm.trashedUsers = response.data.data.trashedUsers;
                vm.clearSelectedUser();

                // refresh the list of roles
                vm.getRoles();
            });
        };

        // Deletes selected user then refreshes the users and roles
        vm.resurrectUser = function(trashedUser, $event) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to bring this user back?')
                .content(trashedUser.first_name + ' ' + trashedUser.last_name + ' will be able to log in.')
                .ariaLabel('Confirm resurrect User')
                .targetEvent($event)
                .ok('activate')
                .cancel('cancel');
            $mdDialog.show(confirm).then(function() {
                ajaxCall.makeRequest('/user_management/users/resurrect',{
                    userId: trashedUser.id
                }).then(function(response){
                    vm.users = response.data.data.allUsers;
                    vm.filteredUsers = vm.users;
                    vm.trashedUsers = response.data.data.trashedUsers;
                    vm.filteredTrashedUsers = vm.trashedUsers;
                    vm.clearSelectedUser();

                    // refresh the list of roles
                    vm.getRoles();
                });
            }, function() {
                // canceled resurrecting user
            });
        };
    }
})();

(function() {
  angular.module('noram.module.users')
  .directive('sameAs', function () {
    return {
      require: 'ngModel',
      link: function (scope, elem, attrs, ngModel) {
        ngModel.$parsers.unshift(validate);

        // Force-trigger the parsing pipeline.
        scope.$watch(attrs.sameAs, function () {
          ngModel.$setViewValue(ngModel.$viewValue);
        });

        function validate(value) {
          var isValid = scope.$eval(attrs.sameAs) == value;

          ngModel.$setValidity('same-as', isValid);

          return isValid ? value : undefined;
        }
      }
    };
  });
})();
